import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "status": "published",
  "path": "/uses-tech/",
  "heroImage": "tech-hardware.jpg",
  "title": "Hardware and Software List",
  "description": "Gear and Tech that I am currently using",
  "date": "2020-06-09T00:00:00.000Z",
  "tags": ["tech"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p style={{
      "textAlign": "center"
    }}>**Disclaimer: the image used for this post is not my actual desk setup.**</p>
    <p>{`I decided to join the growing number of folks who are sharing lists of the hardware and software used almost everyday. This list changes frequently but this is what I am using today.`}</p>
    <h3>{`Development Tools`}</h3>
    <ul className="custom checkmark">
  <li>
    <b>Text Editor:</b> VS Code
  </li>
  <li>
    <b>Terminal Emulator:</b> iTerm2
  </li>
    </ul>
    <h3>{`Productivity Tools`}</h3>
    <ul className="custom checkmark">
  <li>
    <b>All-in-one-tool:</b> Notion
  </li>
  <li>
    <b>Email:</b> Fastmail
  </li>
    </ul>
    <h3>{`Design Tools`}</h3>
    <ul className="custom checkmark">
  <li>
    <b>Design Software:</b> Figma
  </li>
    </ul>
    <h3>{`Various Software`}</h3>
    <ul className="custom checkmark ">
  <li>
    <b>macOS Productivity app:</b> Raycast
  </li>
  <li>
    <b>Password Manager:</b> 1Password
  </li>
  <li>
    <b>Browser:</b> Arc
  </li>
  <li>
    <b>Video Editing:</b> DaVinci Resolve
  </li>
  <li>
    <b>VPN:</b> NordVPN
  </li>
    </ul>
    <h3>{`Hardware`}</h3>
    <ul className="custom checkmark ">
  <li>
    <b>Computer:</b> MacBook Pro (16-in, 2019) (2.3 GHz 8-Core Intel Core i9, 16 GB 2667 MHz DDR4)
  </li>
  <li>
    <b>Keyboard:</b> Logitech MX Keys
  </li>
  <li>
    <b>Microphone:</b> Samson Q2U
  </li>
  <li>
    <b>Mouse:</b> Logitech MX Master 3
  </li>
  <li>
    <b>Storage:</b> Synology 2 bay NAS DiskStation DS218j (Diskless)
  </li>
  <li>
    <b>Wireless Headphones:</b> Sony WH-1000XM4
  </li>
  <li>
    <b>Wireless Earbuds:</b> Jabra Elite 65t + Google Pixel Buds A-Series
  </li>
  <li>
    <b>Phone:</b> Pixel 7
  </li>
  <li>
    <b>Webcam:</b> Vitade 960A
  </li>
  <li>
    <b>WiFi:</b> Google Nest Wi-Fi
  </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      